<template>
<div class="Home">
    <div class="container-fluid p-0 ">
        <div class="container-fluid">
            <div class="row ">
                <nav class="navbar navbar-expand-lg navbar-static-top  bg-light" id="navbarscroll">
                    <div class="container-fluid">

                        <a class="navbar-brand" href="#">
                            <img class="logo img-fluid" src="../dist/img/icon.png" alt="Logo" width="53px" height="83px"/>
                        </a>

                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse " id="navbarNavAltMarkup">
                            <div class="col col-lg-11">
                                <div class="navbar-nav md-ms-5">
                                    <ul class="navbar-nav mx-auto justify-content-center navbartxt">
                                        <li class="nav-item text-center mx-auto navbartxt" >
                                            <a class="nav-link" href="#Olio">Olio</a>
                                        </li>
                                        <li class="nav-item text-center mx-auto" >
                                            <a class="nav-link" href="#Storia">Storia</a>
                                        </li>
                                        <li class="nav-item text-center max-auto" >
                                            <a class="nav-link" href="#ChiSiamo">Chi siamo</a>
                                        </li>
                                        <li class="nav-item text-center max-auto" >
                                            <a class="nav-link" href="#Contatti">Contatti</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col justify-content-ms-end justify-content-sm-center">

                                <a class="nav-social mx-2" href="https://www.instagram.com/arinthaevo/">
                                    <img src="../dist/img/instagram.svg" style="width: 200%; height: auto; max-width: fit-content;" alt="Instagram">
                                </a>

                                <a class="nav-social" href="https://www.facebook.com/profile.php?id=100088787096591">
                                    <img src="../dist/img/facebook.svg" style="height: auto; max-width: fit-content;" alt="Facebook">
                                </a>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="overlay">
                    <p class=" text-start">ARINTHA</p>
                    <h2 class=" text-start">Olio extra vergine di Oliva  Mediterraneo</h2>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="5000">
                        <img src="../dist/img/foto3.webp" class="d-block w-100" alt="casolare" />
                    </div>
                    <div class="carousel-item" data-bs-interval="5000">
                        <img src="../dist/img/foto2.webp" class="d-block w-100" alt="olive" />
                    </div>
                    <div class="carousel-item" data-bs-interval="5000">
                        <img src="../dist/img/foto1.webp" class="d-block w-100" alt="raccolta olive" />
                    </div> 
                    <div class="carousel-item" data-bs-interval="5000">
                        <img src="../dist/img/foto4.webp" class="d-block w-100" alt="ulivo" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        

        <div class="container-fluid mt-5 mb-4" id="Olio">
            <h1 class="storiadate text-md-center">Il nostro olio</h1>

            <div class="row row-eq-height justify-content-center ">
                <div class=" col-md-4">
                    <img src="../dist/img/bottiglia.webp" alt="Bottiglia olio" class="img-fluid" width="181px" height="206px" />
                </div>
                <div class=" col-md-4">
                    <div class="row h-25">
                        <div class="card border-0">
                            <div class="card-body">
                                <h1 class="card-title">Gusto</h1>
                                <p class="card-text">Sapore delicato leggermente piccante</p>
                            </div>
                        </div>
                        <hr/>
                    </div>

                    <div class="row h-25">
                        <div class="card border-0">
                            <div class="card-body">
                                <h1 class="card-title">Profumo</h1>
                                <p class="card-text">Un'estasi dei sensi, vivo e forte</p>
                            </div>
                        </div>
                        <hr/>
                    </div>

                    <div class="row h-25">
                        <div class="card border-0">
                            <div class="card-body">
                                <h1 class="card-title">Colore</h1>
                                <p class="card-text">Verde dorato</p>
                            </div>
                        </div>
                        <hr/>
                    </div>

                    <div class="row h-25 p-4">
                        <div>
                            <img src="../dist/img/bio.webp" alt="biologico" class="img-fluid"  >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid pt-5 px-4" id="Storia">
            <h1 class="storiadate text-md-center">Storia</h1>

            <div class="row justify-content-md-center">
                <div class="col col-eq-height g-5">

                    <div class="row justify-content-center colonne">
                        <div class="col-md-8 row-eq-height">
                            <div class="row">
                                <div class="col-md-3 text-md-start">
                                    <h2 class="storiadate">1887</h2>
                                    <hr/>
                                </div>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6">
                                <p class="storiatxt text-start">Francesco Clausi acquisisce la Tenuta Milelli, ubicata  su uno sperone elevato nell’entroterra Silano (CS). Circondata da una forte  presenza di fauna e un’aria di profonda tranquillità, si pongono le basi per un futuro prospero.</p>
                                </div>
                                <div class="col-md-6 align-items-end">
                                    <img src="../dist/img/FrancescoClausi.webp" class="img-fuid" style="width:100%;" alt="casolare"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5 justify-content-center">
                        <div class="col-md-8 row-eq-height">
                            <div class="row flex-row-reverse align-self-end">
                                <div class="col-md-3 text-md-end ">
                                    <h2 class="storiadate">1900</h2>
                                    <hr/>
                                </div>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6 order-md-2">
                                    <p class="storiatxt text-start">Nei primi anni del ‘900 la tenuta Clausi viene ereditata da Massimo Clausi, che rafforza in maniera decisiva il legame con gli abitanti di Bocchigliero (CS),
                                        gli stessi sono soliti partecipare in quegli anni alla raccolta e la produzione dell’Olio che diventa cosi’ un modo per creare legami e tradizioni popolari.</p>
                                </div>
                                <div class="col-md-6 align-items-end order-md-1">
                                    <img src="../dist/img/1900.webp" class="img-fuid" style="width:100%;" alt="ulivo" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5 justify-content-center">
                        <div class="col-md-8 row-eq-height">
                            <div class="row">
                                <div class="col-md-3 text-md-start">
                                    <h2 class="storiadate">1949</h2>
                                    <hr/>
                                </div>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6">
                                    <p class="storiatxt text-start">La tenuta Milelli passa nelle mani di Francesco Clausi che rileva una terra dilaniata dal Dopo Guerra e dalla rivoluzione industriale.
                                        <br/> <br/>
                                        Lo spostamento degli abitanti dal paese alle grandi città crea uno spopolamento di grandi dimensioni, in netta controtendenza , vi è  la produzione olearia della famiglia Clausi, che continua ad esssere una delle pochissime realtà del paese.</p>
                                </div>
                                <div class="col-md-6 align-items-end">
                                    <img src="../dist/img/1949.webp" class="img-fuid" style="width:100%;" alt="Francesco Clausi" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5 justify-content-center">
                        <div class="col-md-8 row-eq-height">
                            <div class="row flex-row-reverse align-self-end">
                                <div class="col-md-3 text-md-end ">
                                    <h2 class="storiadate">2009</h2>
                                    <hr/>
                                </div>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6 order-md-2">
                                    <p class="storiatxt text-start">Le proprietà passa agli eredi: Massimo e Giovanna Clausi.
                                        <br/>Nello specifico,Giovanna  getterà le basi per la modernizzazione del contesto aziendale.
                                        <br/>Nasce cosi’  l’azienda Giovanna Clausi, che si pone come obiettivo quello di continuare il lavoro svolto dalle tre generazioni.</p>
                                </div>
                                <div class="col-md-6 align-items-end order-md-1" >
                                    <img src="../dist/img/2009.webp" class="img-fuid" style="width:100%;" alt="proprietà"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid justify-content-center p-5 mt-5" id="ChiSiamo">
            <h1 class="storiadate text-md-center">Chi siamo</h1>

            <div class="row justify-content-center">
                <div class="col-md-8">

                    <div class="row row-v" >
                        <div class="card p-4" style="background-color:#586B0A" >
                            <div class="card-body">
                                <h4 class="text-v">L’ azienda agricola ‘Clausi’ di Clausi Giovanna ha deciso di effettuare un  operazione di rebranding, nasce così il nome Arintha.
                                    <br/>Άριθα ( in greco antico) , incarna la storia del paese di Bocchigliero (CS), dove nasce il forte legame  tra la famiglia  Clausi e il borgo stesso.
                                    <br/>Oggi si presenta come  un’ azienda storica rimodernizzata, affinchè la tradizione e  i  sapori del buon olio non siano mai dimenticati.
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col">
                            <img src="../dist/img/Chisiamo.webp" class="img-fluid mx-auto d-block" style="width:57%" alt="olive" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div class="containerimgcalabria justify-content-center" >
                <img src="../dist/img/calabriadesktop.webp" class="img-fluid" alt="Calabria Bocchigliero">
            </div>


        <footer class="footer" style="background-color: #F5E6C9;" id="Contatti">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-4 col-md-4 mt-3 ">

                        <div class="row">
                            <ul class="list-unstyled ms-sm-5 text-sm-start">
                                <p class="navbartxt">Links</p>
                                <li class="list-group-item mx-auto" >
                                    <a class="navbartxt text-decoration-none" href="#Olio">Olio</a>
                                </li>
                                <li class="list-group-item mx-auto" >
                                    <a class="navbartxt text-decoration-none" href="#Storia">Storia</a>
                                </li>
                                <li class="list-group-item max-auto" >
                                    <a class="navbartxt text-decoration-none" href="#ChiSiamo">Chi siamo</a>
                                </li>

                                    <a class="nav-social mx-2" href="https://www.instagram.com/arinthaevo/">
                                        <img src="../dist/img/instagram.svg" style="width: 200%; height: auto; max-width: fit-content;" alt="Instagram">
                                    </a>

                                    <a class="nav-social" href="https://facebook.com">
                                        <img src="../dist/img/facebook.svg" style="height: auto; max-width: fit-content;" alt="Facebook">
                                    </a>

                            </ul>

                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 pt-5 justify-content-center">
                        <p class="footertxt" style="  font-family: 'Heebo';">
                            <b>Contatti:<br/>
                            giovanna.clausi@pec.it<br/>
                            arinthaolioevo@gmail.com<br/>
                            +39 3333170955</b>
                        </p>
                    </div>
                    <div class="col-sm-4 col-md-4 d-flex justify-content-sm-end justify-content-center">
                        <img src="../dist/img/Opera.webp" width="30%;" alt="opera" />
                    </div>
                </div>
            </div>
        </footer>

    </div>
</div>
</template>

<script>
    export default{
        name: "NavBar"
    }
</script>

<style>

    .overlay {
        color:#fff;
        position:absolute;
        z-index:12;
        top:30%;
        left:5%;
        text-align:center;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 700;
        font-size: 80px;
    }

    @media (max-width: 767px) {
        .overlay{
        color:#fff;
        position:absolute;
        z-index:12;
        top:10%;
        left:5%;
        text-align:center;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
    }
} 

    .navbar{
        background: color #f9f9f5;
        width: 100%;
        white-space: nowrap;
    }

    .center {
        margin: auto;
        width: 50%;
        padding: 10px;
    }

    .containerimgcalabria{
        display: flex;
        position: relative;
        text-align: center;
        color: black;
    }

    hr {
        margin-top: 0;
        margin-bottom: 0;
    }
    .text-v{
        color:white;
        font-family: 'Playfair Display Regular';
        font-style: normal;
    }

    .row-v{
        height:auto;
        width: auto;
        background-color: #586B0A;
    }

    .navbartxt{
        font-family: 'Heebo';
        font-weight: 700;
    }


    .storiatxt{
        font-family: 'Playfair Display Regular';
    }

    .storiadate{
        font-family: 'Playfair Display';
    }

    @media (min-width: 1024px) {

        .carousel-inner {
            max-height: 100vh;
        }
    } 

.footer ul {
  padding:0;
  list-style:'Heebo';
  line-height:1.6;

  font-size:14px;
  margin-bottom:0;
}

.footer ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
  font-family: 'Heebo';
}

</style>